import * as React from "react"
import {graphql} from "gatsby";
import HallwayHeroesNavigation from "../../components/education/hallwayHeroesNavigation";
import InnerLayout from "../../components/global/innerLayout"
import LargeCarousel from "../../components/global/largeCarousel";
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import "./humane-heroes-basic.scss"

export const query = graphql`
    query($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "hallwayHeroesBasicPage", id: $id) {
          ... on Craft_hallwayHeroesBasicPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            uri
            title
            contentExtendedButtonsImgOptionsEducation1
            educationOverviewHeroCarousel {
              ... on Craft_educationOverviewHeroCarousel_slide_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteEducationGeneral_Asset {
                     altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
      }
    }
  `

const HumaneHeroesBasicPage = ({ data, pageContext }) => {

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd hallway-heroes-basic-page">

                <PageTitle title={data.craft.page.title} className="hallway-heroes" />

                <div className="content-page">

                    <div className="container-small">

                        <LargeCarousel slides={data.craft.page.educationOverviewHeroCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true} />


                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <HallwayHeroesNavigation active={data.craft.page.uri}/>
                            </div>
                            <div className="content-right">

                                {parse(data.craft.page.contentExtendedButtonsImgOptionsEducation1)}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default HumaneHeroesBasicPage
